// import React, { useState } from "react";
// import "./contact_us.css";
// import leftIcon from "../../img/Group 86.png";
// import pr3 from "../../img/smallBorderL2.png";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import privacyPolicy from "../../docs/privacyAmpliworks.pdf";

// import { useInView } from "react-intersection-observer";
// import { useSpring, animated } from "react-spring";

// // import nodeoutlook from 'nodejs-nodemailer-outlook'

// const Contact_us = () => {
//   const [ref, inView] = useInView({ threshold: 0.01 });

//   const props = useSpring({
//     to: {
//       opacity: inView ? 1 : 0,
//       transform: inView ? "translateX(0)" : "translateX(-20%)",
//     },
//     config: { duration: 1000 },
//   });

//   const props2 = useSpring({
//     to: {
//       opacity: inView ? 1 : 0,
//       transform: inView ? "translateX(0)" : "translateX(20%)",
//     },
//     config: { duration: 1000 },
//   });

//   const props3 = useSpring({
//     to: {
//       opacity: inView ? 1 : 0,
//       transform: inView ? "translateY(0)" : "translateY(100%)",
//     },
//     config: { duration: 1000 },
//   });
//   const [isChecked, setIsChecked] = useState(false);

//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");
//   const [subject, setSubject] = useState("");

//   const [emailError, setEmailError] = useState("");
//   const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

//   const emailSubject = `${subject}`;
//   const emailBody = `Email From : ${name}\nEmail is : ${email}\n${message}`;

//   const handleSubmit = (e) => {
//     // e.preventDefault();

//     // Validate email
//     let isValid = true;
//     if (!isValidEmail(email)) {
//       setEmailError("Invalid email format");
//       isValid = false;
//     } else {
//       setEmailError("");
//     }

//     if (!isValid) {
//       return;
//     }

//     const payload = {
//       subject: emailSubject,
//       text: emailBody,
//       from: "AmpliWorks Notifications",
//       to: "sales@ampliworks.com",
//       // to: "mohammed.yunus@onusworks.com",
//       // to:'mdahaan24@gmail.com' 
//     };
//     // http://localhost:3000/api/ampliworks/sendEmail
//     // https://demo.wikiworks.in/api/ampliworks/sendEmail
//     fetch("https://demo.wikiworks.in/api/ampliworks/sendEmail", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "*",
//       },
//       body: JSON.stringify(payload),
//       mode: "cors",
//     })
//       .then((resp) => {
//         resp.json();
//       })
//       .then((data) => {
//         console.log("data is ", data);
//       })
//       .catch((error) => {
//         console.error("Error is : ", error);
//       });
//     setName("");
//     setEmail("");
//     setSubject("");
//     setMessage("");
//     toast.success("Email Sent Successfully! We will be reaching out you soon");
//   };

//   return (
//     <div id="ContactUs" className="contactContainer">
//       <animated.div ref={ref} style={{ ...props }} className="leftContact">
//         <img style={{ width: "30%" }} src={leftIcon} alt="" />
//         <div className="paraCont">
//           <p className="add1">AmpliWorks Technologies</p>
//           <p style={{ width: "80%" }} className="add1">
//             1201 North Market Street, Suite 111, Wilmington, DE 19801 (P)
//           </p>
//         </div>
//         <p className="phHeader">GET SUPPORT</p>
//         <p className="time">Mon-Fri: 9:00 AM – 6:00 PM (EST)</p>

//         <div className="phEmailContainer">
//           <i
//             className="fa-solid fa-phone"
//             style={{ color: "white", fontSize: "24px" }}
//           ></i>
//           <p className="phEmailText">+1-302-558-5877</p>
//         </div>

//         <div className="phEmailContainer">
//           <i
//             class="fa-solid fa-envelope"
//             style={{ color: "white", fontSize: "24px" }}
//           ></i>
//           <a
//             href="mailto:sales@ampliworks.com"
//             style={{ textDecoration: "none", listStyle: "none" }}
//           >
//             <p className="phEmailText">sales@ampliworks.com</p>
//           </a>
//         </div>
//         <div className="phEmailContainer">
//           <i
//             class="fa-solid fa-fax"
//             style={{ color: "white", fontSize: "24px" }}
//           ></i>
//           <a
//             // href="mailto:sales@ampliworks.com"
//             style={{ textDecoration: "none", listStyle: "none" }}
//           >
//             <p className="phEmailText">+1-302-558-5878</p>
//           </a>
//         </div>
//       </animated.div>
//       <animated.div ref={ref} style={{ ...props }} className="rightContact">
//         <p className="rHeader">CONTACT US</p>
//         <img
//           style={{ width: "15%", objectFit: "contain", marginTop: "1rem" }}
//           src={pr3}
//           alt="pr2"
//         />

//         <div className="formContainer">
//           <form
//             onSubmit={(e) => {
//               e.preventDefault();
//             }}
//           >
//             <input
//               type="text"
//               placeholder="Name"
//               value={name}
//               onChange={(e) => {
//                 setName(e.target.value);
//               }}
//             />
//             <input
//               type="email"
//               placeholder="Official email"
//               value={email}
//               onChange={(e) => {
//                 setEmail(e.target.value);
//               }}
//             />
//             {emailError && <p className="errorText">{emailError}</p>}
//             <input
//               type="text"
//               placeholder="Organization Name"
//               value={subject}
//               onChange={(e) => {
//                 setSubject(e.target.value);
//               }}
//             />
//             <textarea
//               placeholder="Please let us know how can we help"
//               rows="4"
//               value={message}
//               onChange={(e) => {
//                 setMessage(e.target.value);
//               }}
//             ></textarea>
//             <div className="consent">
//               <input
//                 type="checkbox"
//                 id="privacyCheckbox"
//                 onChange={() => {
//                   setIsChecked(!isChecked);
//                 }}
//                 style={{ width: "4%" }}
//               />
//               <label htmlFor="privacyCheckbox">
//                 <span className="consentText">
//                   I have read the{" "}
//                   <a target="_blank" href={privacyPolicy}>
//                     Privacy Policy
//                   </a>
//                 </span>
//               </label>
//             </div>
//             <button
//               onClick={() => {
//                 if (!isChecked) {
//                   toast.warning(
//                     "Please fill out all required fields correctly and accept the terms"
//                   );
//                 } else {
//                   handleSubmit();
//                 }
//               }}
//               type="submit"
//               // disabled={!isChecked}
//               className={isChecked ? "btnActive" : "btn"}
//             >
//               {/* <div className="sendTxt" style={{ fontWeight: "bold" }}> */}
//               Submit
//               {/* </div> */}
//             </button>
//           </form>

//           {/* <form>
//                         <input type="text" placeholder='Name'  />
//                         <input type="email" placeholder='E-mail'   />
//                         <input type="text" placeholder='Subject'/>
//                         <textarea placeholder='Message' rows="4"  ></textarea>
//                         <div className="consent">
//                             <input type="checkbox" id='privacyCheckbox' onChange={() => { setIsChecked(!isChecked) }} style={{ width: '7%' }} />
//                             <label htmlFor="privacyCheckbox"> Add a label element and associate it with the checkbox
//                                 <span className="consentText">I have read the </span>{' '}
//                                 <a href="/">Privacy Policy</a>
//                             </label>
//                         </div>
//                         <button className={isChecked ? 'btnActive' : 'btn'}><div className='sendTxt' style={{ fontWeight: 'bold' }}  >SUBMIT</div></button>
//                     </form> */}
//         </div>
//       </animated.div>
//       <ToastContainer position="top-left" style={{ marginTop: "100px" }} />
//     </div>
//   );
// };

// export default Contact_us;

import React, { useState } from "react";
import { send } from "@emailjs/browser"; // Importing send from emailjs/browser
import "./contact_us.css"; // Include the updated CSS file here
import leftIcon from "../../img/Group 86.png";
import pr3 from "../../img/smallBorderL2.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import privacyPolicy from "../../docs/privacyAmpliworks.pdf";

import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";

const Contact_us = () => {
  const [ref, inView] = useInView({ threshold: 0.01 });
  const props = useSpring({
    to: {
      opacity: inView ? 1 : 0,
      transform: inView ? "translateX(0)" : "translateX(-20%)",
    },
    config: { duration: 1000 },
  });
  const [isChecked, setIsChecked] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false); // State for loader

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Handle the form submission for sending email via EmailJS
  const handleSubmit = (e) => {
    e.preventDefault();
    
    let isValid = true;
    if (!isValidEmail(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!isValid) return;

    setLoading(true); // Start loader

    const payload = {
      subject: subject,
      email: email,
      name: name,
      message: ` 
        Name: ${name} \n
        Email: ${email} \n
        Subject: ${subject} \n
        Message: ${message}
      `,
      to_name: "Ampliworks Team",  // This can stay as-is or be customized
    };

    // Send the email using EmailJS service
    send(
      "service_1e5xeqo",  // Replace with your template ID
      "template_apoj14w",   // Replace with your EmailJS service ID
      payload,
      "piFdp5IfrbeQZ9qA-"       // Replace with your user ID from EmailJS
    )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          toast.success("Email Sent Successfully! We will be reaching out to you soon.");
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
        },
        (err) => {
          console.log("FAILED...", err);
          toast.error("Something went wrong. Please try again.");
        }
      )
      .finally(() => {
        setLoading(false);  // End loader
      });
  };

  return (
    <div id="ContactUs" className="contactContainer">
      <animated.div ref={ref} style={{ ...props }} className="leftContact">
        <img style={{ width: "30%" }} src={leftIcon} alt="" />
        <div className="paraCont">
          <p className="add1">AmpliWorks Technologies</p>
          <p style={{ width: "80%" }} className="add1">
            1201 North Market Street, Suite 111, Wilmington, DE 19801 (P)
          </p>
        </div>
        <p className="phHeader">GET SUPPORT</p>
        <p className="time">Mon-Fri: 9:00 AM – 6:00 PM (EST)</p>

        <div className="phEmailContainer">
          <i className="fa-solid fa-phone" style={{ color: "white", fontSize: "24px" }}></i>
          <p className="phEmailText">+1-302-558-5877</p>
        </div>

        <div className="phEmailContainer">
          <i className="fa-solid fa-envelope" style={{ color: "white", fontSize: "24px" }}></i>
          <a href="mailto:sales@ampliworks.com" style={{ textDecoration: "none", listStyle: "none" }}>
            <p className="phEmailText">sales@ampliworks.com</p>
          </a>
        </div>
      </animated.div>

      <animated.div ref={ref} style={{ ...props }} className="rightContact">
        <p className="rHeader">CONTACT US</p>
        <img style={{ width: "15%", objectFit: "contain", marginTop: "1rem" }} src={pr3} alt="pr2" />

        <div className="formContainer">
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Official email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && <p className="errorText">{emailError}</p>}

            <input
              type="text"
              placeholder="Organization Name"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              placeholder="Please let us know how can we help"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <div className="consent">
              <input
                type="checkbox"
                id="privacyCheckbox"
                onChange={() => setIsChecked(!isChecked)}
                style={{ width: "4%" }}
              />
              <label htmlFor="privacyCheckbox">
                <span className="consentText">
                  I have read the{" "}
                  <a target="_blank" href={privacyPolicy}>
                    Privacy Policy
                  </a>
                </span>
              </label>
            </div>

            <button
              type="submit"
              className={isChecked ? "btnActive" : "btn"}
              disabled={!isChecked || loading} // Disable when loading or not checked
            >
              {loading ? (
                <div className="loader"></div> // Using the CSS loader
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </animated.div>

      <ToastContainer position="top-left" style={{ marginTop: "100px" }} />
    </div>
  );
};

export default Contact_us;
